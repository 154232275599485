export default defineNuxtPlugin(() => {
  const router = useRouter()

  router.beforeEach((to, _, next) => {
    if (to.path === '/' || to.path.startsWith('/content'))
      next()
    else
      location.href = to.fullPath
    // window.open(to.fullPath, '_blank')
  })
})

import dayjs from 'dayjs'
import { useLocalStorage } from '@vueuse/core'

export interface PromotionCodeData {
  time: number
  code: string
  from?: string // 考古加隐藏邀请码输入框
}

const expirationTime = 24 * 60 * 60 * 1000

export function useRegistrationPromotionCode() {
  const promotionCodeData = useLocalStorage('promotionCodeData', {} as PromotionCodeData, {
    initOnMounted: true,
  })
  const setPromotionCodeData = (code: string, from?: string) => {
    if (!code)
      return false
    promotionCodeData.value = {
      code,
      from,
      time: dayjs().valueOf(),
    }
  }
  const getPromotionCode = () => {
    if (!promotionCodeData.value.code)
      return ''

    const codeTime = promotionCodeData.value.time
    if (codeTime) {
      const curTime = dayjs().valueOf()
      const _expirationTime = promotionCodeData.value?.from === 'kgj' ? 15 * expirationTime : expirationTime // 从考古加过来的15天后过期
      if (curTime - codeTime < _expirationTime)
        return promotionCodeData.value.code

      else
        promotionCodeData.value = {} as PromotionCodeData
    }
  }

  return {
    setPromotionCodeData,
    getPromotionCode,
    promotionCodeData,
  }
}

<script setup lang="ts">
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import '@ds-gala-front-app/shared/style'
// import 'element-plus/dist/index.css'
import '@ds-gala-front-app/shared/scss'

const route = useRoute()

if (import.meta.client) {
  // 邀请码
  if (route.query.promotionCode) {
    const { setPromotionCodeData } = useRegistrationPromotionCode()
    setPromotionCodeData(route.query.promotionCode as string)
  }
}
</script>

<template>
  <ElConfigProvider :locale="zhCn">
    <NuxtLayout>
      <NuxtPage keepalive />
    </NuxtLayout>
  </ElConfigProvider>
</template>

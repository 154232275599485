import revive_payload_client_fx7uMcalXY from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_eslint@8.57.0_floating-vue@5.2.2_sa_57oimnmwxw2x45spgh24nh3h4a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5I6JD9g2Ek from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_eslint@8.57.0_floating-vue@5.2.2_sa_57oimnmwxw2x45spgh24nh3h4a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hnCteVE5ka from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_eslint@8.57.0_floating-vue@5.2.2_sa_57oimnmwxw2x45spgh24nh3h4a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_QgqvQzPe2v from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_eslint@8.57.0_floating-vue@5.2.2_sa_57oimnmwxw2x45spgh24nh3h4a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/content/.nuxt/components.plugin.mjs";
import prefetch_client_vPHB86st7P from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_eslint@8.57.0_floating-vue@5.2.2_sa_57oimnmwxw2x45spgh24nh3h4a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/app/apps/content/.nuxt/unocss.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/app/apps/content/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/app/apps/content/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_SCyY5tH7hq from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.3_eslint@8.57.0_floating-vue@5.2.2_sa_57oimnmwxw2x45spgh24nh3h4a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import request_8cwBTcUfTa from "/app/apps/content/plugins/request.ts";
import router_client_W01CLn04zL from "/app/apps/content/plugins/router.client.ts";
import volcengine_client_GIRk2RbujG from "/app/apps/content/plugins/volcengine.client.ts";
import vue_query_wrmMkNpEpe from "/app/apps/content/plugins/vue-query.ts";
export default [
  revive_payload_client_fx7uMcalXY,
  unhead_5I6JD9g2Ek,
  router_hnCteVE5ka,
  check_outdated_build_client_QgqvQzPe2v,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vPHB86st7P,
  unocss_MzCDxu9LMj,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_SCyY5tH7hq,
  request_8cwBTcUfTa,
  router_client_W01CLn04zL,
  volcengine_client_GIRk2RbujG,
  vue_query_wrmMkNpEpe
]
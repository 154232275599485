import { default as _91_46_46_46all_93T4itKVpQaUMeta } from "/app/apps/content/pages/[...all].vue?macro=true";
import { default as _91id_93Qj9CoAvEo5Meta } from "/app/apps/content/pages/content/detail/[id].vue?macro=true";
import { default as indexJBLXLZvvwJMeta } from "/app/apps/content/pages/content/index.vue?macro=true";
import { default as indexE7i7fr7R4CMeta } from "/app/apps/content/pages/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46all_93T4itKVpQaUMeta?.name ?? "all",
    path: _91_46_46_46all_93T4itKVpQaUMeta?.path ?? "/:all(.*)*",
    meta: _91_46_46_46all_93T4itKVpQaUMeta || {},
    alias: _91_46_46_46all_93T4itKVpQaUMeta?.alias || [],
    redirect: _91_46_46_46all_93T4itKVpQaUMeta?.redirect,
    component: () => import("/app/apps/content/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Qj9CoAvEo5Meta?.name ?? "content-detail-id",
    path: _91id_93Qj9CoAvEo5Meta?.path ?? "/content/detail/:id()",
    meta: _91id_93Qj9CoAvEo5Meta || {},
    alias: _91id_93Qj9CoAvEo5Meta?.alias || [],
    redirect: _91id_93Qj9CoAvEo5Meta?.redirect,
    component: () => import("/app/apps/content/pages/content/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJBLXLZvvwJMeta?.name ?? "content",
    path: indexJBLXLZvvwJMeta?.path ?? "/content",
    meta: indexJBLXLZvvwJMeta || {},
    alias: indexJBLXLZvvwJMeta?.alias || [],
    redirect: indexJBLXLZvvwJMeta?.redirect,
    component: () => import("/app/apps/content/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: indexE7i7fr7R4CMeta?.name ?? "index",
    path: indexE7i7fr7R4CMeta?.path ?? "/",
    meta: indexE7i7fr7R4CMeta || {},
    alias: indexE7i7fr7R4CMeta?.alias || [],
    redirect: indexE7i7fr7R4CMeta?.redirect,
    component: () => import("/app/apps/content/pages/index.vue").then(m => m.default || m)
  }
]
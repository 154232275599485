export default defineNuxtPlugin(() => {
  const request = $fetch.create({
    baseURL: `/api/v1`,
    retry: false,

    onRequest: (ctx) => {
      if (import.meta.server) {
        // Forward cookies to the target
        ctx.options.headers = useRequestHeaders(['cookie', 'host', 'referer'])
      }
    },

    onResponse: ({ response }) => {
      if (response.ok && response._data.success) {
        const success = response._data.success
        const result = success.isCrypto ? JSON.parse(decryptData(success.result)) : success.result
        response._data = result
      }
    },
    onRequestError: (error) => {
      console.error('[fetch error]', error.error.message, error)
    },
  })

  // Expose to useNuxtApp().$request
  return {
    provide: {
      request,
    },
  }
})

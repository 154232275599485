export default defineNuxtPlugin(() => {
  const { ENV } = useRuntimeConfig().public

  // 注册火山引擎
  window.collectEvent('init', {
    app_id: ENV === 'prod' ? 595403 : 595402,
    channel: 'cn',
    log: false,
    autotrack: true,
    spa: true,
    enable_stay_duration: true,
    // enable_debug: true,
  })
  window.collectEvent('start')
})

export const appCdnURL = "//cdn-static.glodastory.com/ds-gala-front-app-content/"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"},{"rel":"prefetch","href":"https://mtrcdn.datastory.com.cn/slibs/fonts/ds-icon/iconfont.css","as":"style"},{"rel":"prefetch","href":"https://mtrcdn.datastory.com.cn/slibs/fonts/ds-iconf/iconfont.css","as":"style"}],"style":["body {\n          background-color: #f9f5ff;\n        }"],"script":["var links = ['https://mtrcdn.datastory.com.cn/slibs/fonts/ds-icon/iconfont.css','https://mtrcdn.datastory.com.cn/slibs/fonts/ds-iconf/iconfont.css'];\n        for(let i=0;i<links.length;i++){\n          var link = document.createElement('link');\n          link.rel = 'stylesheet';\n          link.href = links[i];\n          document.head.appendChild(link);\n        }"],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false